import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Sidebar from "../../components/homeMenu/sideBar"
import ProjectsBanner from '../../components/ProjectsBanner'
import ProjectsImage from '../../components/ProjectsImage'
import ProjectsInfo from '../../components/ProjectsInfo'
import ProjectsFeatures from '../../components/ProjectsFeatures'
import ProjectsMap from '../../components/ProjectsMap'
import ProjectsText from '../../components/ProjectsText'
import ProjectsRelated from '../../components/ProjectsRelated'
import Form from "../../components/FormCasa"
import { connect } from 'react-redux'
import { toggleWhiteSidebar } from "../../state/app"

const NeonPage = ({ dispatch, location }) => {
    const { t } = useTranslation()

    useEffect(() => {
        dispatch(toggleWhiteSidebar(false))
    }, [dispatch])

    var dataGlobal = t("global", { returnObjects: true })
    var dataProjetos = t("projetos", { returnObjects: true })

    return (
        <Layout
            data={dataGlobal}
            hideForm
            location={location}
        >
            <Seo title="Neon" />
            <Sidebar
                content={dataGlobal.sidebar}
                projetosPortfolio={t("portfolio", { returnObjects: true }).projetos}
                projetosVenda={t("projetosVenda", { returnObjects: true }).projetos}
            />
            <ProjectsBanner
                data={dataProjetos.projects.neon.menu}
                slider={dataProjetos.projects.neon.slider}
            />
            <ProjectsImage
                data={dataProjetos.projects.neon.image}
                shadow
            />
            <ProjectsInfo
                data={dataProjetos.projects.neon.info}
            />
            <ProjectsText
                data={dataProjetos.projects.neon.info}
            />
            <ProjectsFeatures
                data={dataProjetos.projects.neon.info.features}
            />
            <ProjectsMap
                data={dataProjetos.projects.neon.map}
                snazzyLink='https://snazzymaps.com/embed/364686'
            />
            <Form
                data={dataGlobal.form}
                white
                msgContent={dataGlobal.msgMarkPhone}
            />
            <ProjectsRelated
                data={dataProjetos.cards}
                projectId={11}
            />
        </Layout>
    )
}

export default connect()(NeonPage)

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["projetos", "global", "projetosVenda", "portfolio"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`